/* istanbul ignore file: todo(eran): not running image tests on this file yet */
/* eslint-disable @typescript-eslint/no-unsafe-argument */

import React from 'react';
import {ImageUiTpaWrapper} from './ImageUiTpaWrapper';
import autobind from 'auto-bind-es5';
import s from './ProductMedia.scss';
import {HoverType, ImageModeId, ImageRatioId, IProduct, ProductType} from '../../../../types/galleryTypes';
import {ImageResizeOptions} from 'wix-ui-tpa';
import {DigitalProductBadge} from '@wix/wixstores-client-common-components/dist/es/src/icons/dist';
import {IGalleryGlobalProps} from '../../../../gallery/galleryGlobalStrategy';
import {Carousel, CarouselAriaLabels} from '../../Carousel/Carousel';
import {
  IProvidedTranslationProps,
  withTranslations,
} from '@wix/wixstores-client-common-components/dist/es/src/outOfIframes/translations';
import classNames from 'classnames';
import {Experiments} from '../../../../constants';
import {ConditionalRender} from '../../../../category/components/ConditionalRender/ConditionalRender';

export interface IProductImageProps extends IGalleryGlobalProps {
  product: IProduct;
  isMobile: boolean;
  hoverType: string;
  imageModeId: number;
  classNames: {thumbnail: string; image: string};
  children?: React.ReactChild[] | React.ReactChild;
  textsMap: {
    digitalProductBadgeAriaLabel: string;
  };
}

interface IProductImageState {
  imageContainerDimensions: {width: number; height: number};
}

export enum ProductMediaDataHook {
  Images = 'ProductMediaDataHook.Images',
  ProductMedia = 'ProductMediaDataHook.ProductMedia',
  Badge = 'ProductMediaDataHook.Badge',
}

export const MAX_CAROUSEL_IMAGES = 5;
export const getProductMediaDataHook = (key: number) => `${ProductMediaDataHook.ProductMedia}-${key}`;

export const ratioIdToRatioMap = {
  [ImageRatioId._3x2]: 3 / 2,
  [ImageRatioId._4x3]: 4 / 3,
  [ImageRatioId._1x1]: 1,
  [ImageRatioId._3x4]: 3 / 4,
  [ImageRatioId._2x3]: 2 / 3,
  [ImageRatioId._16x9]: 16 / 9,
  [ImageRatioId._9x16]: 9 / 16,
};

export class ProductImageComponent extends React.Component<
  IProductImageProps & IProvidedTranslationProps,
  IProductImageState
> {
  constructor(props) {
    super(props);
    autobind(this);
  }

  private readonly imageContainerRef = React.createRef<HTMLDivElement>();

  public renderDigitalBadge = () => {
    return (
      <div
        className={s.productBadge}
        data-hook={ProductMediaDataHook.Badge}
        aria-label={this.props.textsMap.digitalProductBadgeAriaLabel}>
        <DigitalProductBadge />
      </div>
    );
  };

  private get imageRatio() {
    const {
      globals: {styles, stylesParams, shouldShowMobile},
    } = this.props;
    return styles.get(stylesParams[shouldShowMobile ? 'mobile:galleryImageRatio' : 'galleryImageRatio']);
  }

  private renderImages() {
    const {
      hoverType,
      imageModeId,
      isMobile,
      product,
      classNames: {thumbnail: externalThumbnailClass, image: externalImageClass},
      globals: {shouldShowImageCarousel},
    } = this.props;

    let showAmountOfPhotos: number = 1;

    const shouldShowSecondaryImageByHoverType = !isMobile && hoverType === HoverType.Alternate;

    if (product.media.length >= 2) {
      if (shouldShowImageCarousel) {
        showAmountOfPhotos = Math.min(product.media.length, MAX_CAROUSEL_IMAGES);
      } else if (shouldShowSecondaryImageByHoverType) {
        showAmountOfPhotos = 2;
      }
    }

    return Array.from({length: showAmountOfPhotos}, (_value, key) => (
      <ImageUiTpaWrapper
        hoverType={hoverType as HoverType}
        mediaItemIndex={key}
        key={key}
        globals={this.props.globals}
        wrapperClassName={externalThumbnailClass}
        imageClassName={externalImageClass}
        product={product}
        data-hook={getProductMediaDataHook(key)}
        resize={imageModeId === ImageModeId.Crop ? ImageResizeOptions.cover : ImageResizeOptions.contain}
        containerAspectRatio={ratioIdToRatioMap[this.imageRatio]}
      />
    ));
  }

  public render() {
    const {
      product,
      isMobile,
      globals: {shouldShowImageCarousel, isLiveSiteMode, isPreviewMode, useExperiments},
      t,
    } = this.props;

    const ariaLabels: CarouselAriaLabels = {
      arrowLeft: t('arrowPrevious'),
      arrowRight: t('arrowNext'),
    };

    const shouldWrapWithImageCarousel = shouldShowImageCarousel && product.media.length > 1;
    const shouldAllowRemoveDigitalBadge = useExperiments.enabled(Experiments.AllowRemoveDigitalBadge);
    return (
      <div
        className={classNames(s.productImages, 'heightByImageRatio', `heightByImageRatio${this.imageRatio}`, {
          [s.borderRadiusOnHoverZoomFix]: useExperiments.enabled(Experiments.GalleryFixImageBorderRadiusOnHoverZoom),
        })}
        ref={this.imageContainerRef}
        aria-live={'assertive'}
        data-hook={ProductMediaDataHook.Images}>
        {shouldWrapWithImageCarousel ? (
          <Carousel
            ariaLabels={ariaLabels}
            isMobile={isMobile}
            shouldShowArrowsOnHover={isLiveSiteMode || isPreviewMode}>
            {this.renderImages()}
          </Carousel>
        ) : (
          this.renderImages()
        )}
        {shouldAllowRemoveDigitalBadge && product.productType === ProductType.DIGITAL ? (
          <ConditionalRender by={'gallery_showDigitalBadge'}>{this.renderDigitalBadge()}</ConditionalRender>
        ) : (
          product.productType === ProductType.DIGITAL && this.renderDigitalBadge()
        )}
        {this.props.children}
      </div>
    );
  }
}

export const ProductMedia = withTranslations()(ProductImageComponent);
