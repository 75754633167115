import React, {CSSProperties, useEffect, useRef, useState} from 'react';
import classNames from 'classnames';
import s from './Clamp.scss';
import {TextButton, TextButtonPriority} from 'wix-ui-tpa/cssVars';
import {useEnvironment} from '@wix/yoshi-flow-editor';

export enum ClampDataHook {
  ReadMoreButton = 'ClampDataHook.ReadMoreButton',
  ReadLessButton = 'ClampDataHook.ReadLessButton',
  HiddenTextCopy = 'ClampDataHook.HiddenTextCopy',
}

export type ClampProps = {
  enabled: boolean;
  text: string;
  className: string;
  readMoreLessLinkClassName: string;
  readMoreText: string;
  readLessText: string;
  maxLines: number;
  handleClampClick?: Function;
  dataHook?: string;
};

// istanbul ignore next: cant test with jsdom, should be tested by sled
export const Clamp: React.FunctionComponent<ClampProps> = ({
  children,
  text,
  className,
  readMoreLessLinkClassName,
  readMoreText,
  readLessText,
  enabled,
  dataHook,
  maxLines,
  handleClampClick,
  // eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
  const [isClamped, setIsClamped] = useState(true);
  const [shouldShowReadMoreLessLink, setShouldShowReadMoreLessLink] = useState(false);
  const textRef = useRef<HTMLSpanElement>(null);
  const ellipsisRef = useRef<HTMLSpanElement>(null);
  const descriptionRef = useRef<HTMLDivElement>(null);
  const [textToDisplay, setTextToDisplay] = useState<string>(text);
  const [state, forceUpdate] = useState(null);
  const {isMobile} = useEnvironment();

  const getHiddenTextOffsetTopData = React.useCallback(
    (hiddenTextNodes: ChildNode[]) => {
      const offsetTops = new Set(hiddenTextNodes.map((node: HTMLSpanElement) => node.offsetTop));
      const numberOfLines = offsetTops.size;
      const maxAllowedOffsetTop = [...offsetTops][maxLines - 1];
      return {numberOfLines, maxAllowedOffsetTop};
    },
    [maxLines]
  );

  useEffect(() => {
    if (!enabled) {
      return;
    }

    const onResize = () => forceUpdate({});
    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, [enabled]);

  useEffect(() => {
    if (!enabled) {
      return;
    }
    if (!descriptionRef) {
      return;
    }
    descriptionRef.current?.style?.setProperty('-webkit-line-clamp', 'unset');
  }, [enabled, descriptionRef]);

  useEffect(() => {
    if (!enabled) {
      return;
    }

    const hiddenTextNodes = getHiddenTextNodes();
    const {numberOfLines, maxAllowedOffsetTop} = getHiddenTextOffsetTopData(hiddenTextNodes);
    if (numberOfLines <= maxLines) {
      setTextToDisplay(text);
      setShouldShowReadMoreLessLink(false);
      return;
    }

    while ((textRef.current?.lastChild as HTMLSpanElement).offsetTop > maxAllowedOffsetTop) {
      const lastTextNode = hiddenTextNodes.pop();
      textRef.current?.removeChild(lastTextNode);
    }

    const remainingNodesTextContent = hiddenTextNodes.map((node) => node.textContent).join('');
    setTextToDisplay(`${remainingNodesTextContent.trimEnd()}…`);
    setShouldShowReadMoreLessLink(true);
  }, [
    text,
    textRef,
    maxLines,
    textRef.current?.parentElement.clientWidth,
    isMobile,
    state,
    getHiddenTextOffsetTopData,
    enabled,
  ]);

  function getHiddenTextNodes() {
    const allChildNodes = [...(textRef.current?.childNodes ?? [])];
    const ellipsisIndex = allChildNodes.findIndex((x) => x === ellipsisRef.current);
    return allChildNodes.slice(0, ellipsisIndex);
  }

  function splitTextBySpaceAndMaxLength() {
    // eslint-disable-next-line prefer-named-capture-group
    const textParts = text.split(/(\s+)/);
    for (let i = 0; i < textParts.length; i++) {
      if (textParts[i].length <= 10) {
        continue;
      }

      const splitByMaxNumberOfChars = textParts[i].match(/.{1,5}/g);
      textParts.splice(i, 1, ...splitByMaxNumberOfChars);
    }

    return textParts;
  }

  const ReadMoreLink = () => (
    <TextButton
      className={readMoreLessLinkClassName}
      priority={TextButtonPriority.link}
      onClick={() => {
        setIsClamped(!isClamped);
        handleClampClick?.(!isClamped);
      }}
      data-hook={isClamped ? ClampDataHook.ReadMoreButton : ClampDataHook.ReadLessButton}>
      {isClamped ? readMoreText : readLessText}
    </TextButton>
  );

  function HiddenTextCopy() {
    const textRefKey = textRef.current?.parentElement.clientWidth;
    const splitText = splitTextBySpaceAndMaxLength();

    return (
      <div className={classNames(s.textContainer, s.hidden, className)} data-hook={ClampDataHook.HiddenTextCopy}>
        <span ref={textRef} key={textRefKey}>
          {splitText.map((part, i) => (
            <span key={i}>{part}</span>
          ))}
          <span ref={ellipsisRef}>{'… '}</span>
          <ReadMoreLink />
        </span>
      </div>
    );
  }

  const nonClampedHtml = (
    <div className={classNames(s.textContainer, className)} data-hook={dataHook}>
      {children}
    </div>
  );

  const style = {'--max-lines': maxLines} as CSSProperties;
  const clampedHtml = (
    <div className={s.clampContainer}>
      <div
        className={classNames(s.textContainer, className, {[s.clamp]: isClamped})}
        data-hook={dataHook}
        ref={descriptionRef}
        style={style}>
        {isClamped ? textToDisplay : text}
        {
          /*istanbul ignore next: cant test with jsdom, will be tested by sled*/ shouldShowReadMoreLessLink && (
            <>
              {' '}
              <ReadMoreLink />
            </>
          )
        }
      </div>
      <HiddenTextCopy />
    </div>
  );

  return enabled ? clampedHtml : nonClampedHtml;
};
