import {CustomCssVarsFnParams} from '../../../../baseCustomCssVars';
import {SliderArrowsPlacement} from '../../../../constants';

/* istanbul ignore file */
export const arrowsNavigationWrapperCssVars = ({styleParams: styles}: CustomCssVarsFnParams) => {
  const arrowsSize = styles.numbers.gallery_sliderArrowsSize;
  const arrowsButtonBorderWidth = styles.numbers.gallery_sliderArrowsContainerBorderWidth;
  const arrowsOnImage = styles.numbers.gallery_sliderArrowsPlacement === SliderArrowsPlacement.Inside;
  const showSliderNavigationArrows = styles.booleans.gallery_showSliderNavigationArrows;
  const totalArrowButtonSize = arrowsSize + arrowsButtonBorderWidth;

  const productHasVisibleOutline = () => {
    const hasVisibleBorder =
      styles.strings.gallery_borderWidth?.value && styles.strings.gallery_borderWidth.value !== '0';
    if (hasVisibleBorder) {
      return true;
    }

    const rgbaProductBackgroundAlphaValue = styles.colors.gallery_productBackground?.match(/[.?\d]+/g)?.[3] ?? '0';
    return rgbaProductBackgroundAlphaValue !== '0';
  };

  return {
    arrowsBaseOpacity: styles.booleans.gallery_sliderShowArrowsOnHoverOnly ? 0 : 1,
    arrowsContainerMargin: arrowsOnImage ? '0 16px' : 'revert',
    arrowContainerAlignSelf: productHasVisibleOutline() ? 'center' : 'revert',
    arrowsNavigationWrapperContentWidth:
      arrowsOnImage || !showSliderNavigationArrows ? '100%' : `calc(100% - ${totalArrowButtonSize} * 2px)`,
    arrowsReferenceHeight: productHasVisibleOutline() ? '100%' : 'unset',
  };
};
